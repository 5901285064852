import Snackbar from '@mui/material/Snackbar';

import React, { useContext, forwardRef } from 'react';
import { SnackbarContext } from '../App';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const CustomSnackbar = ({ ...props }) => {
  const { open, setOpen, message } = useContext(SnackbarContext)
  props.open = open ? true : false
  props.message = message
  props.onClose = () => setOpen(false)
  props.autoHideDuration = 6000

  return (
    <Snackbar {...props}>
      {/* //Todo severity="error"  */}
      <Alert onClose={() => setOpen(false)} severity="warning" variant="hollow" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar