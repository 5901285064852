import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@mui/material'
import React, { useContext, useState } from 'react'
import api from '../api'
import { EventContext } from '../App';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeleteConfirmationDialog = ({ open, setOpen, eventId, attendee, adminId }) => { // Todo: functionality for delete, name for deletion.
  const { event, setEvent } = useContext(EventContext)
  const handleClose = () => {
    setOpen(false)
  }
  const [isConfirmLoading, setIsConfirmLoading] = useState(false)

  const onDeleteGuest = async () => {
    try {
      setIsConfirmLoading(true)
      await api.deleteAttendee(eventId, attendee.attendeeIdHash, adminId)
      setEvent({
        ...event,
        attendees: Object.entries(event.attendees)
          .filter(([, a]) => a.attendeeIdHash !== attendee.attendeeIdHash)
          .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
      })
    } catch (e) {
      console.error("Did not successfully delete attendee", e)
    } finally {
      setIsConfirmLoading(false)
      setOpen(false)
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth='sm'
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {attendee && <DialogTitle>{`Are you sure you want to remove ${attendee.name} from this event?`}</DialogTitle>}
      <DialogContent>
        <Grid container sx={{
          // backgroundColor: 'gray',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
          spacing={2}
        >
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={onDeleteGuest} disabled={isConfirmLoading}>Confirm Delete</Button>
      </DialogActions>
    </Dialog >
  )
}
