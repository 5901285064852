import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { Slide } from "@mui/material";
import React, { forwardRef } from "react";
import { Typography } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HelpDialog = ({ open, setOpen }) => {
  return (<Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    fullWidth={true}
    maxWidth='sm'
    onClose={() => setOpen(false)}
  >
    <DialogTitle>{`What is this?`}</DialogTitle>
    <DialogContent>
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
        {
          `This is a simple webpage where you can create events and invite your friends.
        
          Perhaps for your next dinner party, soccer training, board game gathering or outdoor adventure.
        
        We will never share your data to any third party companies.
        
        We are delighted to have you here. Try us out!
      `} </Typography>

    </DialogContent></Dialog>)
}
export default HelpDialog