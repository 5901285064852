// const API_BASE_URL = process.env.API_BASE_URL || 'http://localhost:4000/dev'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const request = {
    get: async (url, headers) => {
        return fetch(`${API_BASE_URL}${url}`, headers)
    },
    post: async (url, body, adminId = '') => {
        return fetch(`${API_BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                adminId
            },
            body: JSON.stringify(body)
        })
    },

    adminPost: async (url, body, adminId = '') => {
        return fetch(`${API_BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                adminId,
            },
            body: JSON.stringify(body)
        })
    },

    patch: async (url, body, adminId = '') => {
        return fetch(`${API_BASE_URL}${url}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                adminId,
            },
            body: JSON.stringify(body)
        })
    },
    delete: async (url, adminId = '') => {
        // headers = Object.assign()
        return fetch(`${API_BASE_URL}${url}`, {
            method: 'DELETE',
            headers: {
                adminId
            }
        })
    }
}

const getEvent = async (id) => {
    const response = await request.get(`/event/${id}`)
    if (!response.ok) {
        throw new Error('Failed to get event')
    }
    return await response.json()
}

const createEvent = async (title, description, fromDate, toDate) => {
    try {
        const response = await request.post('/event', {
            title,
            description,
            fromDate,
            toDate
        })
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to create event')
        }
    } catch (error) {
        console.error('Error creating event', error)
    }
}

const updateEvent = async (eventId, adminId, title, description, fromDatetime, toDateTime) => {
    try {
        const response = await request.patch(`/event/${eventId}`, {
            title,
            description,
            fromDate: fromDatetime,
            toDate: toDateTime
        }, adminId)
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to update event')
        }
    } catch (error) {
        console.error('Error updating event', error)
    }
}

const joinEvent = async (eventId, name) => {
    try {
        const response = await request.post(`/event/${eventId}/attendee`, { name })
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to join event')
        }
    } catch (error) {
        console.error('Error joining event', error)
    }
}

const updateAttendee = async (eventId, attendeeId, attendee) => {
    try {
        const response = await request.patch(`/event/${eventId}/attendee/${attendeeId}`, attendee)
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to update attendee')
        }
    } catch (error) {
        console.error('Error updating attendee', error)
    }
}

const writePost = async (eventId, attendeeId, message, adminId) => {
    try {
        const dt = new Date()
        const response = await request.post(`/event/${eventId}/post`, {
            message,
            date: dt,
            attendeeId,
        }, adminId)
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to post on wall')
        }
    } catch (error) {
        console.error('Error posting to wall', error)
    }
}

const getPosts = async (eventId, attendeeId, adminId) => {
    try {
        const response = await request.get(`/event/${eventId}/post`, {
            headers: {
                'attendeeId': attendeeId,
                'adminId': adminId
            }
        })
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to get all posts')
        }
    } catch (error) {
        console.error('Error creating event', error)
    }
}

const deleteAttendee = async (eventId, attendeeIdHash, adminId) => {
    try {
        const response = await request.delete(
            `/event/${eventId}/attendee/${encodeURIComponent(attendeeIdHash)}`,
            adminId
        )
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to delete attendee')
        }
    } catch (error) {
        console.error('Error deleting attendee')
    }
}

const getAttendee = async (eventId, attendeeIdHash, adminId) => {
    try {
        const response = await request.get(
            `/event/${eventId}/attendee/${attendeeIdHash}`,
            adminId
        )
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to get attendee')
        }
    } catch (error) {
        console.error('Error get attendee')
    }
}

const deleteEvent = async (eventId, adminId) => {
    try {
        const response = await request.delete(`/event/${eventId}`, {
            headers: {
                adminId,
            }
        })
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to delete event')
        }
    } catch (error) {
        console.error('Error in deleting event', error)
    }
}

const sendAdminEmail = async (eventId, email, adminId) => {
    try {
        const response = await request.post(`/event/${eventId}/notification`,
            {
                email,
                adminId
            }
        )
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to send email')
        }
    } catch (error) {
        console.error('Error in sending email', error)

    }
}
const sendAttendeeEmail = async (eventId, email, attendeeId) => {
    try {
        const response = await request.post(`/event/${eventId}/notification`,
            {
                email,
                attendeeId
            }
        )
        if (response.ok) {
            return await response.json()
        } else {
            throw new Error('Failed to send email')
        }
    } catch (error) {
        console.error('Error in sending email', error)

    }
}

const api = {
    createEvent,
    updateEvent,
    getEvent,
    joinEvent,
    updateAttendee,
    writePost,
    getPosts,
    deleteAttendee,
    getAttendee,
    deleteEvent,
    sendAttendeeEmail,
    sendAdminEmail,
    API_BASE_URL
}

export default api
